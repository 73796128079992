<template>
  <localization :language="'ru-RU'">
    <intl :locale="'ru'">
      <CRow class="table-actions-row">
        <v-col sm="12" cols="12" lg="4" class="pl-0">
          <breadcrumbs :items="crumbs" />
        </v-col>
        <v-col
          sm="12"
          md="12"
          lg="8"
          class="action-btns d-flex justify-end align-center flex-column flex-md-row"
        ></v-col>
      </CRow>
      <Grid
        ref="grid"
        class="request"
        :resizable="true"
        :sortable="true"
        :filterable="true"
        :pageable="gridPageable"
        :filter="filter"
        @datastatechange="dataStateChange"
        :take="take"
        :skip="skip"
        :sort="sort"
        :page-size="pageSize"
        :total="total"
        :data-items="dataResult"
        :columns="columns"
        :expand-field="'expanded'"
        :detail="detailTemplate"
        @expandchange="expandChange"
        @sortchange="sortChange"
      >
        <template v-slot:actions="{ props: { dataItem } }">
          <td>
            <div class="actionsColumn">
              <CButton
                :disabled="[STATUS_CANCELLED].includes(+dataItem.status)"
                @click="openChangeStatusModalHandler(dataItem)"
                class="actions-btn btn-square btn-info"
              >
                <i class="la la-pencil"></i>
                Изменить статус
              </CButton>
            </div>
          </td>
        </template>
        <template v-slot:status_filter>
          <drop-down-list
            class="status-filter"
            text-field="name"
            data-item-key="key"
            :default-item="{key: null, name: 'Все'}"
            :data-items="WRITE_DOWN_POINTS_STATUSES"
            @change="selectFilter('status', $event.value.key)"
          />
        </template>
        <template v-slot:date_filter>
          <div class="d-flex align-end custom">
            <datepicker
              :default-show="false"
              class="date-filter"
              :start-date-input-settings="startDateInputSettings"
              :value="value"
              :end-date-input-settings="endDateInputSettings"
              @change="dateChangeHandler"
            />
            <button
              @click="clearDates"
              title="Очистить"
              type="button"
              class="k-button k-button-icon k-clear-button-visible"
            >
              <span class="k-icon k-i-filter-clear"></span>
            </button>
          </div>
        </template>
        <template v-slot:status="{ props: { dataItem } }">
          <td>
            {{ WRITE_DOWN_POINTS_STATUSES.find((status) => +status.key === +dataItem.status).name }}
          </td>
        </template>
        <template v-slot:detail="{ props: { dataItem } }">
          <div>
            <v-tabs v-model="tab">
              <v-tab key="additionalServices" href="#additionalServices">
                Дополнительная информация
              </v-tab>
              <v-tab key="client" href="#client">Дилер</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item key="additionalServices" id="additionalServices">
                <div class="py-5 px-5">
                  <table class="table table-striped m-table">
                    <tr>
                      <td class="request-detail__table-cell" style="width: 250px">
                        <div class="table-cell__description">
                          <span>Основание списания</span>
                          <CButton
                            class="btn-edit btn-square btn-info"
                            @click="openChangeDescriptionModalHandler(dataItem)"
                          >
                            <i class="la la-pencil"></i>
                          </CButton>
                        </div>
                      </td>
                      <td class="request-detail__table-cell">
                        <p>
                          {{ dataItem.description }}
                        </p>
                      </td>
                    </tr>
                    <RequestWriteDownPointsFilesRow
                      title="Акт/накладная/протокол"
                      :docs="dataItem.act_files"
                      @upload-files="(files) => uploadFilesHandler(files, 'actFiles', dataItem.id)"
                      @delete-file="(fileId) => deleteFileHandler(fileId, TYPE_ACT)"
                    />
                    <RequestWriteDownPointsFilesRow
                      title="Счет"
                      :docs="dataItem.account_files"
                      @upload-files="(files) => uploadFilesHandler(files, 'accountFiles', dataItem.id)"
                      @delete-file="(fileId) => deleteFileHandler(fileId, TYPE_ACCOUNT)"
                    />
                    <RequestWriteDownPointsFilesRow
                      title="Прочие подтверждающие документы"
                      :docs="dataItem.other_files"
                      @upload-files="(files) => uploadFilesHandler(files, 'otherFiles', dataItem.id)"
                      @delete-file="(fileId) => deleteFileHandler(fileId, TYPE_OTHER)"
                    />
                  </table>
                </div>
              </v-tab-item>
              <v-tab-item key="client" id="client">
                <div class="py-5 px-5">
                  <table class="table table-striped m-table">
                    <tr>
                      <td class="request-detail__table-cell" style="width: 250px">
                        Наименование дилера:
                      </td>
                      <td class="request-detail__table-cell">
                        <p>{{ dataItem.dealer_name }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td class="request-detail__table-cell">КССС дилера:</td>
                      <td class="request-detail__table-cell">
                        <p>{{ dataItem.dealer_unique_id }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td class="request-detail__table-cell">ФИО контактного лица:</td>
                      <td class="request-detail__table-cell">
                        <p>{{ dataItem.dealer_fio }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td class="request-detail__table-cell">Должность контактного лица:</td>
                      <td class="request-detail__table-cell">
                        <p>{{ dataItem.dealer_position }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td class="request-detail__table-cell">
                        Телефон контактного лица:
                      </td>
                      <td class="request-detail__table-cell">
                        <p>{{ dataItem.dealer_phone }}</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </template>
      </Grid>
      <change-description-modal
        v-if="currentItem"
        :show-dialog="toggleChangeDescriptionModal"
        :current-request="currentItem"
        @close="closeChangeDescriptionModalHandler"
        @submit="submitChangeDescriptionModalHandler"
      />
      <change-status-modal 
        v-if="currentItem" 
        :show-dialog="showEntityModal" 
        :current-item="currentItem"
        @close="closeChangeStatusModalHandler"
        @submit="submitChangeStatusModalHandler"
      />
    </intl>
  </localization>
</template>

<script>
import {
  IntlProvider,
  LocalizationProvider,
} from '@progress/kendo-vue-intl';
import moment from 'moment'
import { Grid } from "@progress/kendo-vue-grid";
import { DateRangePicker } from "@progress/kendo-vue-dateinputs";
import { DropDownList} from "@progress/kendo-vue-dropdowns";
import '@/views/grid_elements/translations';
import Breadcrumbs from "@/views/components/breadcrumbs.vue";
import TableMixin from "@/views/grid_elements/mixins/TableMixin";
import ResizableTableMixin from "@/views/grid_elements/mixins/ResizableTableMixin";
import api from '@/api';
import ChangeRequestWriteDownDescriptionModal
  from "@/views/content/agent-user/modals/ChangeRequestWriteDownDescriptionModal.vue";
import WRITE_DOWN_POINTS_STATUSES, { STATUS_DONE, STATUS_CANCELLED } from "@/config/request_write_down_points_statuses";
import {TYPE_ACT, TYPE_OTHER, TYPE_ACCOUNT} from "@/config/request_write_down_points_docs_types";
import ChangeRequestWriteDownStatusModal
  from "@/views/content/agent-user/modals/ChangeRequestWriteDownStatusModal.vue";
import RequestWriteDownPointsFilesRow from "@/views/components/write-down-points/RequestWriteDownPointsFilesRow.vue";

export default {
  name: 'request-write-down-points',
  components: {
    RequestWriteDownPointsFilesRow,
    DropDownList,
    localization: LocalizationProvider,
    intl: IntlProvider,
    Grid,
    breadcrumbs: Breadcrumbs,
    datepicker: DateRangePicker,
    ChangeDescriptionModal: ChangeRequestWriteDownDescriptionModal,
    ChangeStatusModal: ChangeRequestWriteDownStatusModal,
  },
  mixins: [TableMixin, ResizableTableMixin],
  data() {
    return {
      TYPE_ACT,
      TYPE_OTHER,
      TYPE_ACCOUNT,
      STATUS_DONE,
      STATUS_CANCELLED,
      WRITE_DOWN_POINTS_STATUSES,
      skip: 0,
      take: 10,
      pageSize: 10,
      total: 10,
      tab: '',
      value: {
        start: null,
        end: null,
      },
      startDateInputSettings: {
        label: 'От',
        format: 'dd.MM.yyyy',
      },
      endDateInputSettings: {
        format: 'dd.MM.yyyy',
        label: 'До',
      },
      gridPageable: { pageSizes: true },
      columns: [
        {
          field: 'id',
          title: 'Действия',
          cell: 'actions',
          minWidth: 150,
          width: '150px',
          filterable: false,
          sortable: false,
        },
        {
          field: 'request_number',
          title: 'Номер заявки',
          type: 'text',
          filter: 'text',
          minWidth: 160,
          width: '160px',
        },
        {
          field: 'date_request',
          title: 'Дата создания заявки',
          type: 'date',
          filter: 'date',
          format: '{0:d}',
          minWidth: 440,
          width: '440px',
          filterCell: 'date_filter'
        },
        {
          field: 'status',
          title: 'Статус',
          cell: 'status',
          filterCell: 'status_filter',
          minWidth: 160,
          width: '160px',
        },
        {
          field: 'dealer_name',
          title: 'Дилер',
          type: 'text',
          minWidth: 250,
          width: '250px',
        },
        {
          field: 'dealer_unique_id',
          title: 'КССС',
          type: 'text',
          filter: 'text',
          minWidth: 160,
          width: '160px',
        },
        {
          field: 'points_debited',
          title: 'Сумма списания',
          type: 'number',
          filter: 'text',
          minWidth: 160,
          width: '160px',
        },
        {
          field: 'description',
          title: 'Основание для списания',
          type: 'text',
          filter: 'text',
          minWidth: 250,
          width: '250px',
        },
        {
          field: 'dealer_fio',
          title: 'ФИО',
          type: 'text',
          minWidth: 250,
          width: '250px',
        },
        {
          field: 'dealer_phone',
          title: 'Номер телефона',
          type: 'text',
          minWidth: 250,
          width: '250px',
        },
        {
          field: 'dealer_email',
          title: 'E-mail',
          type: 'text',
          minWidth: 250,
          width: '250px',
        },
      ],
      detailTemplate: 'detail',
      toggleChangeDescriptionModal: false,
    }
  },
  methods: {
    openChangeStatusModalHandler(requestItem) {
      this.currentItem = { ...requestItem };
      this.showEntityModal = true;
    },
    closeChangeStatusModalHandler() {
      this.currentItem = null;
      this.showEntityModal = false;
    },
    submitChangeStatusModalHandler() {
      this.getData();
      this.closeChangeStatusModalHandler();
    },
    openChangeDescriptionModalHandler(requestItem) {
      this.currentItem = { ...requestItem };
      this.toggleChangeDescriptionModal = true;
    },
    closeChangeDescriptionModalHandler() {
      this.currentItem = null;
      this.toggleChangeDescriptionModal = false;
    },
    submitChangeDescriptionModalHandler() {
      this.getData();
      this.closeChangeDescriptionModalHandler();
    },
    dateChangeHandler({value}) {
      this.value.start = value.start;
      this.value.end = value.end;
      const dateStart = moment(new Date(value.start)).format('YYYY.MM.DD');
      const dateEnd = moment(new Date(value.end)).format('YYYY.MM.DD');
      if (dateEnd === '1970.01.01') {
        this.setFilter('date', 'gte', dateStart);
      } else if (dateStart === dateEnd) {
        this.removeFilter('date');
        this.setFilter('date', 'gtedatetime', dateStart);
      } else {
        this.removeFilter('date');
        const obj = [
          {
            field: 'date',
            operator: 'gte',
            value: dateStart,
          },
          {
            field: 'date',
            operator: 'lte',
            value: dateEnd,
          },
        ];
        this.setMultipleFilter('date', obj);
      }
      this.updateTable(true);
    },
    fetchData: api.request_write_down_points.getRequestsWriteDownPoints,
    clearDates() {
      this.value.start = null;
      this.value.end = null;
      this.removeFilter('date');
      this.removeFilter('date');
      this.updateTable(true);
    },
    async uploadFilesHandler(files, type, requestId) {
      const formData = new FormData();
      formData.append('id', requestId);
      Array.from(files).forEach((file) => formData.append(`${type}[]`, file));
      try {
        await api.request_write_down_points.uploadRequestsWriteDownPointsFiles(formData);
        this.getData();
      } catch (e) {
        console.error(e)
      }
    },
    async deleteFileHandler(id, type) {
      try {
        await api.request_write_down_points.deleteRequestsWriteDownPointsFile(id, type);
        this.getData();
      } catch (e) {
        console.error(e)
      }
    }
  },
  watch: {
    dataResult() {
      this.dataResult.data = this.dataResult.data.map((result) => ({
        ...result,
        act_files: result.docs.filter((doc) => doc.file_type === TYPE_ACT),
        other_files: result.docs.filter((doc) => doc.file_type === TYPE_OTHER),
        account_files: result.docs.filter((doc) => doc.file_type === TYPE_ACCOUNT),
      }));
    }
  },
  async mounted() {
    await this.getData()
  }
}
</script>

<style scoped lang="sass">
.request-detail
  &__table-cell
    border-bottom-width: 1px

.btn-edit
  padding: .3rem .5rem
  background-color: inherit
  border: none
  margin-left: 1rem
  border-radius: 50% !important
  color: #6f717a
  & i
    font-size: 1.25rem
    height: 1.25rem
    width: 1.25rem

.table-cell
  &__description
    display: flex
    justify-content: space-between
    align-items: center

.actions-btn
  white-space: normal
  padding: 3px 10px !important

  &.btn::after
    content: none !important

</style>