<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog
      width="500"
      id="product-image-picker-modal"
      class="product-attachment-modal"
      :value="showDialog"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title class="headline">
          <span><i class="fa fa-edit mr-2"></i> Изменение статуса заявки</span>
        </v-card-title>
        <v-card-text>
          <form class="m-form m-form--state">
            <ValidationProvider
                name="status"
                rules="required"
                v-slot="{invalid, validated}"
            >
              <v-select
                  v-model="status"
                  :items="availableStatuses"
                  item-value="key"
                  item-text="name"
                  label="Изменить статус на *"
                  :error="invalid && validated"
                  required
              />
            </ValidationProvider>
            <ValidationProvider
              v-if="[STATUS_EDITING, STATUS_CANCELLED].includes(+status)"
              name="name"
              rules="required|max:300"
              v-slot="{invalid, validated}"
            >
              <v-textarea
                v-model="comment"
                label="Причина *"
                :counter="300"
                :rows="1"
                :error="invalid && validated"
                required
              />
            </ValidationProvider>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="closeDialog">Отмена</CButton>
          <CButton :disabled="invalid" @click="handleSubmit(submit)" color="success">
            <i class="fa fa-arrow-right mr-2"></i>
            <span>Изменить статус</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import api from "@/api";
import { showMessage } from "@/lib/toasted";
import EntityModalMixin from "@/views/grid_elements/mixins/EntityModalMixin";
import WRITE_DOWN_POINTS_STATUSES, {
  STATUS_CANCELLED,
  STATUS_EDITED,
  STATUS_ACCEPTED,
  STATUS_EDITING,
  STATUS_DONE
} from "@/config/request_write_down_points_statuses";
import {showConfirm} from "@/lib/swal2";

export default {
  name: "ChangeOrderStatusModal",
  mixins: [ EntityModalMixin ],
  components: { ValidationObserver, ValidationProvider },
  data ()
  {
    return {
      STATUS_EDITING,
      STATUS_CANCELLED,
      status: null,
      comment: '',
      statuses: [],
      showApproveModal: false
    }
  },
  computed: {
    availableStatuses() {
      const currentStatus = WRITE_DOWN_POINTS_STATUSES.find(
        (status) => status.key === +this.currentItem.status,
      );
      if (currentStatus.key === STATUS_DONE) {
        return WRITE_DOWN_POINTS_STATUSES.filter((status) => status.key === STATUS_CANCELLED)
      }
      return WRITE_DOWN_POINTS_STATUSES.filter(
        (status) => ![STATUS_EDITED, STATUS_ACCEPTED, currentStatus.key].includes(status.key)
      );
    }
  },
  methods: {
    async submit () {
      if (this.status === STATUS_DONE) {
        const { isConfirmed } = await showConfirm('Вы уверены, что хотите поменять статус?');
        if (!isConfirmed) return;
      }

      const method = api.request_write_down_points.updateRequestsWriteDownPointsStatus;
      const {
        data: {
          status,
          message
        }
      } = await method( this.currentItem.id, this.status, this.comment );

      showMessage( status, message );
      this.$emit('submit');
    }
  },
}
</script>

<style scoped>
</style>
