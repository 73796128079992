export const STATUS_ACCEPTED = 1;
export const STATUS_DONE = 2;
export const STATUS_EDITING = 3;
export const STATUS_CANCELLED= 4;
export const STATUS_EDITED = 5;

export default [
  { key: STATUS_ACCEPTED, name: 'Принят'},
  { key: STATUS_DONE, name: 'Согласован'},
  { key: STATUS_EDITING, name: 'На доработке'},
  { key: STATUS_CANCELLED, name: 'Отменен'},
  { key: STATUS_EDITED, name: 'Доработан'},
];