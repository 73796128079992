var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var untouched = ref.untouched;
var handleSubmit = ref.handleSubmit;
return [_c('v-dialog',{staticClass:"product-attachment-modal",attrs:{"value":_vm.showDialog,"width":"500","id":"product-image-picker-modal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('i',{staticClass:"fa fa-edit mr-2"}),_c('span',[_vm._v("Редактирование основания для списания")])]),_c('v-card-text',[_c('form',{staticClass:"m-form m-form--state"},[_c('div',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('v-textarea',{ref:"textarea",staticClass:"request-write-down-description",attrs:{"rows":"1","value":_vm.description.replace('Списание баллов за ', ''),"error":invalid && validated,"error-messages":errors},on:{"input":_vm.inputDescriptionHandle},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"request-write-down-description-label"},[_vm._v("Списание баллов за ")]),_c('span',[_vm._v("(введите основание для списания)")])]},proxy:true}],null,true)})]}}],null,true)})],1)])]),_c('v-card-actions',[_c('v-spacer'),_c('CButton',{staticClass:"mr-3",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Отмена")]),_c('CButton',{attrs:{"disabled":invalid,"color":"success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_c('span',[_vm._v("Сохранить")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }