<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog
      :value="showDialog"
      width="500"
      id="product-image-picker-modal"
      class="product-attachment-modal"
    >
      <v-card>
        <v-card-title class="headline">
          <i class="fa fa-edit mr-2"></i>
          <span>Редактирование основания для списания</span>
        </v-card-title>

        <v-card-text>
          <form class="m-form m-form--state">
            <div>
              <ValidationProvider name="name" rules="required|max:300" v-slot="{invalid, validated, errors}">
                <v-textarea
                    ref="textarea"
                    rows="1"
                    class="request-write-down-description"
                    :value="description.replace('Списание баллов за ', '')"
                    :error="invalid && validated"
                    :error-messages="errors"
                    @input="inputDescriptionHandle"
                >
                  <template #label>
                    <span class="request-write-down-description-label">Списание баллов за </span>
                    <span>(введите основание для списания)</span>
                  </template>
                </v-textarea>
              </ValidationProvider>
            </div>
          </form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid" @click="handleSubmit(submit)" color="success">
            <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import api from "@/api";
import {showMessage} from "@/lib/toasted";

export default {
  name: 'change-request-write-down-description-modal',
  components: { ValidationProvider, ValidationObserver },
  props: {
    currentRequest: {
      type: Object,
      default: () => ({ id: '', description: '' })
    },
    showDialog: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      description: this.currentRequest.description
    }
  },
  methods: {
    inputDescriptionHandle(value) {
      this.description = value;
    },
    async submit() {
      const { updateRequestsWriteDownPointsDescription } = api.request_write_down_points
      const { data: { status, message } } = await updateRequestsWriteDownPointsDescription(
          this.currentRequest.id,
          'Списание баллов за ' + this.description
      )
      showMessage(status, message)
      this.$emit('submit', this.description)
    }
  }
}
</script>

<style lang="sass">
.request-write-down-description.v-text-field .v-label--active
  transform: translateY(-24px) scale(.95)
.request-write-down-description-label
  color: rgba(0,0,0,.9)
</style>