<template>
  <tr>
    <td class="request-detail__table-cell" style="width: 250px">
      {{ title }}
    </td>
    <td class="request-detail__table-cell">
      <div class="request-detail__files-list">
        <file-card
            v-for="(doc, index) in docs"
            class="request-detail__file"
            :key="index"
            :link="getFileUrl(doc.docs_url)"
            :name="doc.name"
            :size="fileSizeFormatter(+doc.size) || '-'"
            @delete="deleteFileHandler(doc.id)"
        />
        <CButton
            class="btn-edit btn-square btn-info"
            @click="openCreationFileDialogHandler('uploadActFiles')"
        >
          <v-file-input
              ref="uploadActFiles"
              accept="image/png, image/jpeg, application/pdf"
              v-show="false"
              multiple
              @change="uploadFilesHandler($event)"
          />
          <i class="la la-plus"></i>
        </CButton>
      </div>
    </td>
  </tr>
</template>

<script>
import FileCard from "@/views/components/cards/RequestWriteDownPointsFileCard.vue";
import { showConfirm } from "@/lib/swal2";
import fileSizeFormatter from "@/utils/file_size_formatter";

export default {
  components: { FileCard },
  props: {
    docs: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      fileSizeFormatter,
    }
  },
  methods: {
    getFileUrl(filePath) {
      return `${window.location.protocol}//${filePath}`;
    },
    openCreationFileDialogHandler(fileInput) {
      this.$refs[fileInput].$refs.input.click();
    },
    async uploadFilesHandler(files) {
      this.$emit('upload-files', files)
    },
    async deleteFileHandler(id) {
      const { isConfirmed } = await showConfirm('Вы уверены, что хотите удалить прикрепленный файл?');
      if (!isConfirmed) return;
      this.$emit('delete-file', id)
    }
  }
}
</script>

<style lang="sass" scoped>
.request-detail
  &__files-list
    display: flex
    align-items: center
    flex-wrap: wrap
    float: none
  &__file:not(:last-child)
    margin-right: 8px
  &__table-cell
    border-bottom-width: 1px

.btn-edit
  padding: .3rem .5rem
  background-color: inherit
  border: none
  margin-left: 1rem
  border-radius: 50% !important
  color: #6f717a
  & i
    font-size: 1.25rem
    height: 1.25rem
    width: 1.25rem
</style>