var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var untouched = ref.untouched;
var handleSubmit = ref.handleSubmit;
return [_c('v-dialog',{staticClass:"product-attachment-modal",attrs:{"width":"500","id":"product-image-picker-modal","value":_vm.showDialog},on:{"click:outside":_vm.closeDialog}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('span',[_c('i',{staticClass:"fa fa-edit mr-2"}),_vm._v(" Изменение статуса заявки")])]),_c('v-card-text',[_c('form',{staticClass:"m-form m-form--state"},[_c('ValidationProvider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-select',{attrs:{"items":_vm.availableStatuses,"item-value":"key","item-text":"name","label":"Изменить статус на *","error":invalid && validated,"required":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]}}],null,true)}),([_vm.STATUS_EDITING, _vm.STATUS_CANCELLED].includes(+_vm.status))?_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-textarea',{attrs:{"label":"Причина *","counter":300,"rows":1,"error":invalid && validated,"required":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})]}}],null,true)}):_vm._e()],1)]),_c('v-card-actions',[_c('v-spacer'),_c('CButton',{staticClass:"mr-3",attrs:{"color":"secondary"},on:{"click":_vm.closeDialog}},[_vm._v("Отмена")]),_c('CButton',{attrs:{"disabled":invalid,"color":"success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_c('i',{staticClass:"fa fa-arrow-right mr-2"}),_c('span',[_vm._v("Изменить статус")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }