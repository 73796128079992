<template>
  <div class="card-file">
    <div class="card-file__content">
      <component
        :is="link ? 'a' : 'span'"
        :href="link"
        target="_blank"
        class="card-file__content-name"
      >
        <u><b>{{ name }}</b></u>
      </component>
      <span>{{ size }}</span>
    </div>
    <div class="card-file__actions">
      <button v-if="showDelete" type="button" class="btn card-file__actions-close" @click.stop="deleteHandle">
        <i class="la la-close"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileCard',
  props: {
    link: {
      default: ''
    },
    name: {
      type: [String, Number],
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    showDelete: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    deleteHandle() {
      this.$emit('delete')
    },
  }
}

</script>

<style scoped lang="sass">
.card-file
  display: flex
  justify-content: space-between
  max-width: 180px
  width: 100%
  padding: .571rem
  background-color: #f1f1f1f1

  &__icon, &__content
    margin-right: .57rem

  &__content
    width: 100%
    overflow: hidden
    display: flex
    flex-direction: column
    &-name
      width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

  &__actions
    height: 100%
    &-close
      padding: 0 .14rem
      color: inherit
      background-color: inherit
      border: none
      box-shadow: none
      & i
        font-size: 1rem
        height: 1rem
        width: 1rem
</style>